import { resolveScriptKey } from "unhead";
import { defu } from "defu";
import { useScript as _useScript, injectHead } from "@unhead/vue";
import { parseURL } from "ufo";
import { onNuxtReady, useHead, useNuxtApp, useRuntimeConfig } from "nuxt/app";
import { reactive } from "vue";
import { pick } from "../utils.js";
function useNuxtScriptRuntimeConfig() {
  return useRuntimeConfig().public["nuxt-scripts"];
}
const ValidPreloadTriggers = ["onNuxtReady", "client"];
const PreconnectServerModes = ["preconnect", "dns-prefetch"];
function warmup(_, rel, head) {
  const { src } = _;
  const $url = parseURL(src);
  const isPreconnect = rel && PreconnectServerModes.includes(rel);
  const href = isPreconnect ? `${$url.protocol}${$url.host}` : src;
  const isCrossOrigin = !!$url.host;
  if (!rel || isPreconnect && !isCrossOrigin) {
    return;
  }
  const link = {
    href,
    rel,
    ...pick(_, [
      // shared keys between script and link
      "crossorigin",
      "referrerpolicy",
      "fetchpriority",
      "integrity"
      // ignore id
    ])
  };
  const defaults = { fetchpriority: "low" };
  if (rel === "preload") {
    defaults.as = "script";
  }
  if (isCrossOrigin) {
    defaults.crossorigin = "anonymous";
    defaults.referrerpolicy = "no-referrer";
  }
  return useHead({ link: [defu(link, defaults)] }, { head, tagPriority: "high" });
}
export function useScript(input, options) {
  input = typeof input === "string" ? { src: input } : input;
  options = defu(options, useNuxtScriptRuntimeConfig()?.defaultScriptOptions);
  const id = String(resolveScriptKey(input));
  const nuxtApp = useNuxtApp();
  const head = options.head || injectHead();
  nuxtApp.$scripts = nuxtApp.$scripts || reactive({});
  const exists = !!nuxtApp.$scripts?.[id];
  if (!options.warmupStrategy && ValidPreloadTriggers.includes(String(options.trigger))) {
    options.warmupStrategy = "preload";
  }
  if (options.trigger === "onNuxtReady") {
    options.trigger = onNuxtReady;
  }
  if (import.meta.client) {
    if (!exists) {
      performance?.mark?.("mark_feature_usage", {
        detail: {
          feature: options.performanceMarkFeature ?? `nuxt-scripts:${id}`
        }
      });
    }
  }
  const instance = _useScript(input, options);
  instance.warmup = (rel) => {
    if (!instance._warmupEl) {
      instance._warmupEl = warmup(input, rel, head);
    }
  };
  if (options.warmupStrategy) {
    instance.warmup(options.warmupStrategy);
  }
  const _remove = instance.remove;
  instance.remove = () => {
    instance._warmupEl?.dispose();
    nuxtApp.$scripts[id] = void 0;
    return _remove();
  };
  nuxtApp.$scripts[id] = instance;
  if (import.meta.dev && import.meta.client) {
    let syncScripts = function() {
      nuxtApp._scripts[instance.id] = payload;
      nuxtApp.hooks.callHook("scripts:updated", { scripts: nuxtApp._scripts });
    };
    if (exists) {
      return instance;
    }
    const payload = {
      ...options.devtools,
      src: input.src,
      $script: null,
      events: []
    };
    nuxtApp._scripts = nuxtApp._scripts || {};
    if (!nuxtApp._scripts[instance.id]) {
      head.hooks.hook("script:updated", (ctx) => {
        if (ctx.script.id !== instance.id)
          return;
        payload.events.push({
          type: "status",
          status: ctx.script.status,
          at: Date.now()
        });
        payload.$script = instance;
        syncScripts();
      });
      head.hooks.hook("script:instance-fn", (ctx) => {
        if (ctx.script.id !== instance.id || String(ctx.fn).startsWith("__v_"))
          return;
        payload.events.push({
          type: "fn-call",
          fn: ctx.fn,
          at: Date.now()
        });
        syncScripts();
      });
      payload.$script = instance;
      payload.events.push({
        type: "status",
        status: "awaitingLoad",
        trigger: options?.trigger,
        at: Date.now()
      });
      syncScripts();
    }
  }
  return instance;
}
