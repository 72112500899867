import { defineStore } from "pinia";
import type { Global } from "~~/types/strapi/api/global/content-types/global/global";
import type { Payload } from "~~/types/strapi/common/schemas-to-ts/Payload";
import type { Banner } from "~~/types/strapi/components/global/interfaces/Banner";

export const useGlobalStore = defineStore("globalStore", () => {
  const globalData = ref<Payload<Global> | null>(null);
  const pending = ref(false);

  async function fetchGlobal(): Promise<void> {
    if (globalData.value !== null || pending.value === true) {
      return;
    }

    pending.value = true;
    const { data } = await useApiFetch<Payload<Global>>("/api/global?populate=deep");
    globalData.value = data.value;
    pending.value = false;
  }

  const banner = computed(() => {
    const promotions = globalData.value?.data.attributes.promotions;

    const currentPromotion = promotions?.find((promotion) => isCurrentPromotion(promotion?.from, promotion?.to));

    if (currentPromotion !== undefined) {
      return currentPromotion;
    }

    const defaultPromotion = getDefaultPromotion(globalData.value);

    if (defaultPromotion !== undefined) {
      return defaultPromotion;
    }

    return globalData.value?.data.attributes.banner;
  });

  const isActivePromotion = computed(
    () => isNotNullOrUndefined(banner.value?.from) || isNotNullOrUndefined(banner.value?.to),
  );
  const buyMembershipButtonText = computed(() => banner.value?.button_label ?? "Gratis Probemonat starten");

  return {
    banner,
    buyMembershipButtonText,
    fetchGlobal,
    globalData,
    isActivePromotion,
    pending,
  };
});

function getDefaultPromotion(globalData?: Payload<Global> | null): Banner | undefined {
  return globalData?.data.attributes.promotions?.find(
    (promotion) => isNullOrUndefined(promotion?.from) && isNullOrUndefined(promotion?.to),
  );
}

function isCurrentPromotion(from?: Date, to?: Date): boolean {
  if (isNullOrUndefined(from) || isNullOrUndefined(to)) {
    return false;
  }

  const today = new Date().getTime();
  const fromDate = new Date(from).getTime();
  const toDate = new Date(to).getTime();

  return today >= fromDate && today <= toDate;
}
