
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_930fq9LcYiApMeta } from "/vercel/path0/frontend/app/pages/[...slug].vue?macro=true";
import { default as indexKNh7OURR1TMeta } from "/vercel/path0/frontend/app/pages/[departmentZip]-wien/[healthProfessional]/index.vue?macro=true";
import { default as indexMhsImVFJ7BMeta } from "/vercel/path0/frontend/app/pages/[departmentZip]-wien/index.vue?macro=true";
import { default as _91_46_46_46slug_930oOWzQrQ9XMeta } from "/vercel/path0/frontend/app/pages/blog/[...slug].vue?macro=true";
import { default as indexIg6TQ3R7EwMeta } from "/vercel/path0/frontend/app/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_937DB1KUmlV7Meta } from "/vercel/path0/frontend/app/pages/blog/kategorie/[...slug].vue?macro=true";
import { default as indexsY3IegUDYHMeta } from "/vercel/path0/frontend/app/pages/fachrichtung/[department]/[service]/[location]/[doctor]/index.vue?macro=true";
import { default as indexz0EFyFcroRMeta } from "/vercel/path0/frontend/app/pages/fachrichtung/[department]/[service]/[location]/index.vue?macro=true";
import { default as indexYBA5BA7k5tMeta } from "/vercel/path0/frontend/app/pages/fachrichtung/[department]/[service]/index.vue?macro=true";
import { default as indexABITS6A34HMeta } from "/vercel/path0/frontend/app/pages/fachrichtung/[department]/index.vue?macro=true";
import { default as index6tsPSZ1fkyMeta } from "/vercel/path0/frontend/app/pages/fachrichtung/vorsorgemedizin/[serviceGroup]/[service]/[doctor]/index.vue?macro=true";
import { default as indexeO0xidaYqaMeta } from "/vercel/path0/frontend/app/pages/fachrichtung/vorsorgemedizin/[serviceGroup]/[service]/index.vue?macro=true";
import { default as index477ujpRmX3Meta } from "/vercel/path0/frontend/app/pages/fachrichtung/vorsorgemedizin/[serviceGroup]/index.vue?macro=true";
import { default as indexAgYqNgji8CMeta } from "/vercel/path0/frontend/app/pages/fachrichtung/vorsorgemedizin/index.vue?macro=true";
import { default as fachrichtungeny8jZtTGb36Meta } from "/vercel/path0/frontend/app/pages/fachrichtungen.vue?macro=true";
import { default as indexE6MomzPfSjMeta } from "/vercel/path0/frontend/app/pages/index.vue?macro=true";
import { default as _91slug_93Lu4GOLPrWdMeta } from "/vercel/path0/frontend/app/pages/team/[slug].vue?macro=true";
import { default as index1nTtzfjnpbMeta } from "/vercel/path0/frontend/app/pages/team/index.vue?macro=true";
import { default as vielen_45dankCtHMN9q93NMeta } from "/vercel/path0/frontend/app/pages/vielen-dank.vue?macro=true";
import { default as _91_46_46_46slug_93tLe8Zr7zkiMeta } from "~/pages/[...slug].vue?macro=true";
import { default as indexp9V2p5fxycMeta } from "~/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93HqBsLitTXBMeta } from "~/pages/blog/[...slug].vue?macro=true";
import { default as indexwcDEFiGKxqMeta } from "~/pages/blog/index.vue?macro=true";
import { default as component_45stubhQvGvn3jj9Meta } from "/vercel/path0/frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubhQvGvn3jj9 } from "/vercel/path0/frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/vercel/path0/frontend/app/pages/[...slug].vue")
  },
  {
    name: "departmentZip-wien-healthProfessional",
    path: "/:departmentZip()-wien/:healthProfessional()",
    component: () => import("/vercel/path0/frontend/app/pages/[departmentZip]-wien/[healthProfessional]/index.vue")
  },
  {
    name: "departmentZip-wien",
    path: "/:departmentZip()-wien",
    component: () => import("/vercel/path0/frontend/app/pages/[departmentZip]-wien/index.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug(.*)*",
    component: () => import("/vercel/path0/frontend/app/pages/blog/[...slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexIg6TQ3R7EwMeta || {},
    component: () => import("/vercel/path0/frontend/app/pages/blog/index.vue")
  },
  {
    name: "blog-kategorie-slug",
    path: "/blog/kategorie/:slug(.*)*",
    component: () => import("/vercel/path0/frontend/app/pages/blog/kategorie/[...slug].vue")
  },
  {
    name: "fachrichtung-department-service-location-doctor",
    path: "/fachrichtung/:department()/:service()/:location()/:doctor()",
    meta: indexsY3IegUDYHMeta || {},
    component: () => import("/vercel/path0/frontend/app/pages/fachrichtung/[department]/[service]/[location]/[doctor]/index.vue")
  },
  {
    name: "fachrichtung-department-service-location",
    path: "/fachrichtung/:department()/:service()/:location()",
    meta: indexz0EFyFcroRMeta || {},
    component: () => import("/vercel/path0/frontend/app/pages/fachrichtung/[department]/[service]/[location]/index.vue")
  },
  {
    name: "fachrichtung-department-service",
    path: "/fachrichtung/:department()/:service()",
    meta: indexYBA5BA7k5tMeta || {},
    component: () => import("/vercel/path0/frontend/app/pages/fachrichtung/[department]/[service]/index.vue")
  },
  {
    name: "fachrichtung-department",
    path: "/fachrichtung/:department()",
    meta: indexABITS6A34HMeta || {},
    component: () => import("/vercel/path0/frontend/app/pages/fachrichtung/[department]/index.vue")
  },
  {
    name: "fachrichtung-vorsorgemedizin-serviceGroup-service-doctor",
    path: "/fachrichtung/vorsorgemedizin/:serviceGroup()/:service()/:doctor()",
    meta: index6tsPSZ1fkyMeta || {},
    component: () => import("/vercel/path0/frontend/app/pages/fachrichtung/vorsorgemedizin/[serviceGroup]/[service]/[doctor]/index.vue")
  },
  {
    name: "fachrichtung-vorsorgemedizin-serviceGroup-service",
    path: "/fachrichtung/vorsorgemedizin/:serviceGroup()/:service()",
    meta: indexeO0xidaYqaMeta || {},
    component: () => import("/vercel/path0/frontend/app/pages/fachrichtung/vorsorgemedizin/[serviceGroup]/[service]/index.vue")
  },
  {
    name: "fachrichtung-vorsorgemedizin-serviceGroup",
    path: "/fachrichtung/vorsorgemedizin/:serviceGroup()",
    meta: index477ujpRmX3Meta || {},
    component: () => import("/vercel/path0/frontend/app/pages/fachrichtung/vorsorgemedizin/[serviceGroup]/index.vue")
  },
  {
    name: "fachrichtung-vorsorgemedizin",
    path: "/fachrichtung/vorsorgemedizin",
    meta: indexAgYqNgji8CMeta || {},
    component: () => import("/vercel/path0/frontend/app/pages/fachrichtung/vorsorgemedizin/index.vue")
  },
  {
    name: "fachrichtungen",
    path: "/fachrichtungen",
    meta: fachrichtungeny8jZtTGb36Meta || {},
    component: () => import("/vercel/path0/frontend/app/pages/fachrichtungen.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/frontend/app/pages/index.vue")
  },
  {
    name: "team-slug",
    path: "/team/:slug()",
    meta: _91slug_93Lu4GOLPrWdMeta || {},
    component: () => import("/vercel/path0/frontend/app/pages/team/[slug].vue")
  },
  {
    name: "team",
    path: "/team",
    component: () => import("/vercel/path0/frontend/app/pages/team/index.vue")
  },
  {
    name: "vielen-dank",
    path: "/vielen-dank",
    component: () => import("/vercel/path0/frontend/app/pages/vielen-dank.vue")
  },
  {
    name: "heilmassage-1020-wien",
    path: "/heilmassage-1020-wien",
    component: () => import("~/pages/[...slug].vue")
  },
  {
    name: "hno-arzt-1020-wien",
    path: "/hno-arzt-1020-wien",
    component: () => import("~/pages/[...slug].vue")
  },
  {
    name: "hno-arzt-1180-wien",
    path: "/hno-arzt-1180-wien",
    component: () => import("~/pages/[...slug].vue")
  },
  {
    name: "internist-1180-wien",
    path: "/internist-1180-wien",
    component: () => import("~/pages/[...slug].vue")
  },
  {
    name: "kinderarzt-1020-wien",
    path: "/kinderarzt-1020-wien",
    component: () => import("~/pages/[...slug].vue")
  },
  {
    name: "neurochirurg-1020-wien",
    path: "/neurochirurg-1020-wien",
    component: () => import("~/pages/[...slug].vue")
  },
  {
    name: "ordination-mieten-wien",
    path: "/ordination-mieten-wien",
    component: () => import("~/pages/[...slug].vue")
  },
  {
    name: "orthopaede-1040-wien",
    path: "/orthopaede-1040-wien",
    component: () => import("~/pages/[...slug].vue")
  },
  {
    name: "osteopathie-1020-wien",
    path: "/osteopathie-1020-wien",
    component: () => import("~/pages/[...slug].vue")
  },
  {
    name: "osteopathie-1040-wien",
    path: "/osteopathie-1040-wien",
    component: () => import("~/pages/[...slug].vue")
  },
  {
    name: "osteopathie-1180-wien",
    path: "/osteopathie-1180-wien",
    component: () => import("~/pages/[...slug].vue")
  },
  {
    name: "psychotherapie-1180-wien",
    path: "/psychotherapie-1180-wien",
    component: () => import("~/pages/[...slug].vue")
  },
  {
    name: "psychotherapie-1020-wien",
    path: "/psychotherapie-1020-wien",
    component: () => import("~/pages/[...slug].vue")
  },
  {
    name: "physiotherapie-1180-wien",
    path: "/physiotherapie-1180-wien",
    component: () => import("~/pages/[...slug].vue")
  },
  {
    name: "physiotherapie-1020-wien",
    path: "/physiotherapie-1020-wien",
    component: () => import("~/pages/[...slug].vue")
  },
  {
    name: "physiotherapie-1040-wien",
    path: "/physiotherapie-1040-wien",
    component: () => import("~/pages/[...slug].vue")
  },
  {
    name: "urologe-1180-wien",
    path: "/urologe-1180-wien",
    component: () => import("~/pages/[...slug].vue")
  },
  {
    name: "agb",
    path: "/agb",
    component: () => import("~/pages/index.vue")
  },
  {
    name: "datenschutz",
    path: "/datenschutz",
    component: () => import("~/pages/index.vue")
  },
  {
    name: "fuer-aerzte-therapeuten",
    path: "/fuer-aerzte-therapeuten",
    component: () => import("~/pages/index.vue")
  },
  {
    name: "fuer-unternehmen",
    path: "/fuer-unternehmen",
    component: () => import("~/pages/index.vue")
  },
  {
    name: "generali-rundum-gesund",
    path: "/generali-rundum-gesund",
    component: () => import("~/pages/index.vue")
  },
  {
    name: "gratis-gesundheitscheck-wien",
    path: "/gratis-gesundheitscheck-wien",
    component: () => import("~/pages/index.vue")
  },
  {
    name: "haeufige-fragen",
    path: "/haeufige-fragen",
    component: () => import("~/pages/index.vue")
  },
  {
    name: "impressum",
    path: "/impressum",
    component: () => import("~/pages/index.vue")
  },
  {
    name: "mitgliedschaft",
    path: "/mitgliedschaft",
    component: () => import("~/pages/index.vue")
  },
  {
    name: "ueber-uns",
    path: "/ueber-uns",
    component: () => import("~/pages/index.vue")
  },
  {
    name: "uniqa-partnerzentrum",
    path: "/uniqa-partnerzentrum",
    component: () => import("~/pages/index.vue")
  },
  {
    name: "uniqa-vitalcheck",
    path: "/uniqa-vitalcheck",
    component: () => import("~/pages/index.vue")
  },
  {
    name: "uniqa-vorsorgeprogramm",
    path: "/uniqa-vorsorgeprogramm",
    component: () => import("~/pages/index.vue")
  },
  {
    name: "vorsorge",
    path: "/vorsorge",
    component: () => import("~/pages/index.vue")
  },
  {
    name: "wiener-staedtische",
    path: "/wiener-staedtische",
    component: () => import("~/pages/index.vue")
  },
  {
    name: "blog-preview-slug",
    path: "/blog/preview/:slug",
    component: () => import("~/pages/blog/[...slug].vue")
  },
  {
    name: "blog-index-page",
    path: "/blog/:page(\\d+)",
    meta: indexwcDEFiGKxqMeta || {},
    component: () => import("~/pages/blog/index.vue")
  },
  {
    name: component_45stubhQvGvn3jj9Meta?.name,
    path: "/blog/1",
    component: component_45stubhQvGvn3jj9
  }
]