// @see https://stackoverflow.com/a/73636631/838733
export const useApiFetch: typeof useFetch = (request, initialOpts?) => {
  const opts = { ...initialOpts };
  const headerPrerender = useRequestHeader("x-prerender-revalidate");

  if (headerPrerender !== undefined) {
    if (opts.headers === undefined) {
      opts.headers = {};
    }

    opts.headers["x-prerender-revalidate"] = headerPrerender;
  }

  return useFetch(request, { baseURL: "/cms", ...opts });
};
